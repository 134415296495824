import React from 'react';
import './App.css';
import {BrowserRouter} from 'react-router-dom';
import Base from '@contentpeppergmbh/base';
import {availablePlugins, activePlugins} from './lib/registerPlugins';
import {availableTemplates, activeTemplates} from './lib/registerTemplates';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Base
          availablePlugins={availablePlugins}
          activePlugins={activePlugins}
          availableTemplates={availableTemplates}
          activeTemplates={activeTemplates}
        />
      </div>
      {/* basepath /content */}
    </BrowserRouter>
  );
}

App.displayName = 'App';
export default App;
